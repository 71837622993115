* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto Slab', serif;
}

html {
    height: 100%;
}

body {
    background: #000;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.App {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.dz-title {
    font-size: 160px;
    font-weight: 200;
    margin-bottom: 30px;
    display: flex;
    user-select: none;
    animation: glowingWhite 2s infinite ease-in-out alternate-reverse;
}

.dz-title .main {
    margin-top: 8px;
}

.bracket:nth-of-type(1) {
    margin-right: 15px;
}

.bracket:nth-of-type(3) {
    margin-left: 15px;
}

.App img {
    height: 50px;
    width: 50px;
}

@keyframes glowingWhite {
    from {
        color: #fff;
    }
    to {
        color: rgb(122, 122, 122);
    }
}

@media (max-width: 425px) {
    .dz-title {
        font-size: 120px;
    }
}
